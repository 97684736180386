import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withRouter from 'HOCs/withRouter';
import IconWrapper from 'Components/IconWrapper/IconWrapper';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import { buildURL, getItemUrl } from 'Helpers/url';
import ItemImage from './ItemImage';

import { FormattedMessage as Translation } from 'react-intl';
import classNames from 'classnames';

import { isEmpty } from 'Helpers/objects';
import { relativeDate } from 'Helpers/dates';
import { getItemLocation, getIsStoreInspectedCar, getIsFeaturedAd, isCocoFofoUser, getIsSponsoredAd } from 'Helpers/item';

import withTrack from 'HOCs/withTrack/withTrack';
import withConfig from 'HOCs/withConfig/withConfig';

import ItemFavouriteIcon from 'Components/ItemFavouriteIcon/ItemFavouriteIcon';

import css from './ItemCard.scss';
import { css as uicss } from 'panamera-react-ui';
import { slugify } from 'Helpers/strings';
import { X, WEBP_SVG_TYPE } from 'Constants/images';
import { ITEM_SOURCE, INSPECTION_INFO_STATUS } from 'Constants/items';
import { ITEM_CARD_TYPE, LISTING_TYPES, trustTagHash } from 'Constants/listing';
import { HOME, INSPECTED_CAR_ICN_CLICK } from 'Constants/tracking';
import withBackButtonHandling from 'HOCs/withBackButtonHandling/withBackButtonHandling';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import CallButton from '../components/CallButton/CallButton';
import { PLATFORM } from 'Constants/device.APP_TARGET';
import { noop } from 'Helpers/function';
import { THEMES } from 'Constants/bundles';
import { configSelector } from 'Selectors/config';
import SpinViewTag from './SpinViewTag';
import {
    getLoggedUser,
    getUserDataSelector,
    getDealerDataSelector,
    getDealerPreviewDataSelector
} from 'Selectors/user';
import { userSelector } from 'Selectors/users';
import { USER_TYPE } from 'Constants/users';
import { isMobile } from 'Helpers/devices';
import { CONFIG } from 'Constants/config';
import { setSelectFrom } from 'Actions/track';
import { getSelectFromFilter } from 'Helpers/tracking';
import { getItemWithLocation } from 'Helpers/item';
import { getStatus } from './ItemCardStatus.utils';
import { convertKeysToSnakeCase } from 'Helpers/objects';
import { getUserInfoByCategory } from 'Helpers/users';

const { icons } = uicss;

export class ItemCard extends React.PureComponent {
    static propTypes = {
        config: PropTypes.object.isRequired,
        item: PropTypes.object,
        type: PropTypes.string,
        onView: PropTypes.func,
        className: PropTypes.string,
        showStatistics: PropTypes.bool,
        friendsInCommon: PropTypes.bool,
        trackerOrigins: PropTypes.object,
        enabledRealImpressions: PropTypes.bool,
        visualizationType: PropTypes.oneOf([
            'grid', 'big', 'list'
        ]),
        listingType: PropTypes.string,
        onItemClick: PropTypes.func,
        getListingDescription: PropTypes.func,
        tag: PropTypes.string,
        isAdSuggested: PropTypes.bool,
        source: PropTypes.string,
        openModal: PropTypes.func.isRequired,
        // Added for tracking which ad clicked
        chosenOption: PropTypes.number,
        location: PropTypes.object,
        itemCardType: PropTypes.string,
        track: PropTypes.func,
        trackOrigin: PropTypes.string,
        showInspectionTag: PropTypes.bool,
        extraTrackAttr: PropTypes.object,
        imageHeight: PropTypes.number,
        imageWidth: PropTypes.number,
        generalConfig: PropTypes.object.isRequired,
        userType: PropTypes.string,
        loggedUser: PropTypes.object,
        listingBodyType: PropTypes.string,
        setSelectFromTrack: PropTypes.func,
        categories: PropTypes.object,
        isShowVideoTag: PropTypes.bool,
        showSponseredTag: PropTypes.bool,
        showDealerTag: PropTypes.bool,
        dealerData: PropTypes.object,
        isDealerPreview: PropTypes.bool,
        isDealerProfile: PropTypes.bool,
        isLCPCandidate: PropTypes.bool,
        userIcon: PropTypes.string
    };

    static defaultProps = {
        item: {},
        className: '',
        type: 'public',
        onView: noop,
        showStatistics: false,
        friendsInCommon: false,
        visualizationType: 'grid',
        enabledRealImpressions: false,
        listingType: LISTING_TYPES.ITEMS,
        onItemClick: noop,
        getListingDescription: noop,
        tag: 'li',
        isAdSuggested: false,
        chosenOption: null,
        trackerOrigins: {},
        itemCardType: '',
        track: noop,
        trackOrigin: '',
        showInspectionTag: false,
        extraTrackAttr: {},
        listingBodyType: '',
        setSelectFromTrack: () => {},
        categories: {},
        isShowVideoTag: false,
        showSponseredTag: false,
        showDealerTag: false,
        isDealerPreview: false,
        isDealerProfile: false
    };

    constructor({ item, config, generalConfig, categories }) {
        super();
        this.property = {
            slug: item && getItemUrl(getItemWithLocation(item, config), undefined, categories),
            image: item && item.images && item.images[0] || {},
            price: item && item.price && item.price.value && item.price.value.raw !== 0 ? item.price.value.display : '',
            display: item && item.status && item.status.display,
            monetizerEnabled: config.get('monetizer').enabled,
            date: relativeDate(
                item.display_date || item.created_at_first || item.created_at,
                undefined,
                { suppressHydrationWarning: true },
                true
            )
        };
        const adpvConfig = config.get('adpvAuto');

        this.carInspectedIconUri = adpvConfig && adpvConfig.carInspectedIconUri;
        this.enableNewAdpvFromConfig = adpvConfig && adpvConfig.enable;
        this.carCategory = adpvConfig && adpvConfig.carCategory;
        this.siteCode = config.get('siteCode');
        this.isLatamRegion = config.get('region') === 'latam';
        this.showFavBtn = adpvConfig && adpvConfig.showFavBtn;
        this.featuredTag = adpvConfig && adpvConfig.featuredTag;
        this.phoneInListing = generalConfig?.features?.find(feature => feature.name === 'olxautos_phone_in_listing') || {};
        this.theme = config.get('theme', 'id');
    }

    isVisible = viewingIt => {
        if (!this.props.enabledRealImpressions) {
            return;
        }

        if (viewingIt) {
            const { item } = this.props;
            const { id, title, user_id, monetizationInfo, spell, inspection_info } = item;
            const isFeatured = !!((monetizationInfo && !isEmpty(monetizationInfo.currentProduct))
                || (item.package && (item.package.id || item.package.name)));
            const { id: spell_id = 0 } = spell || {};
            const isInspected = !!getIsStoreInspectedCar(inspection_info);
            const impression = { id, title, user_id, isFeatured, spell_id, isInspected };

            this.props.onView(impression);
        }
    };

    getDefaultSize() {
        const sizeByVisualization = {
            list: 's=272x0',
            grid: 's=272x0',
            big: 's=644x0'
        };

        return sizeByVisualization[this.props.visualizationType];
    }

    renderStatus(display) {
        const { type } = this.props;

        if ((display === 'rejected' || display === 'modify') && type === 'private') {
            return {
                statusClass: css.rejected,
                status: <label><Translation id="rejected" /></label>
            };
        }
        if (display === 'deleted' && type === 'private') {
            return {
                statusClass: css.deleted,
                status: <label><Translation id="deleted" /></label>
            };
        }
        if (display === 'pending' && type === 'private') {
            return {
                statusClass: css.pending,
                status: <label><Translation id="pending" /></label>
            };
        }
        if (display === 'sold') {
            return {
                statusClass: css.sold,
                status: <label><Translation id="sold" /></label>
            };
        }
        if (display === 'outdated' && type === 'private') {
            return {
                statusClass: css.outdated,
                status: <label><Translation id="outdated" /></label>
            };
        }
        if (display === 'hot') {
            return {
                statusClass: css.hot,
                status: <label className={ `${icons['icon-Hot']} ${icons.panameraIcons}` }><Translation id="hot" /></label>
            };
        }
        if (display === 'new') {
            return {
                statusClass: css.new,
                status: <label><Translation id="new" /></label>
            };
        }
        return {};
    }

    onClick = () => {
        const selectFromFilter = getSelectFromFilter(this.props.listingBodyType);

        this.props.setSelectFromTrack(selectFromFilter);
        this.props.onItemClick(this.props.item);
    };

    getLinkToProp() {
        const { item, listingType, isAdSuggested, source, chosenOption = 'null', trackerOrigins, location } = this.props;
        const { slug } = this.property;
        const { feed_version = null } = this.props.extraTrackAttr;
        const state = {
            isAdSuggested,
            source
        };

        // Add fields for tracking related ads
        if (source === ITEM_SOURCE.RELATED) {
            const { id, scoreFactor = 'null' } = item;

            state.relatedAdTracking = {
                impressions: [{
                    adId: id,
                    scoreFactor
                }],
                chosen_option: chosenOption,
                origin: trackerOrigins.ITEM_PAGE
            };
        }

        // Add tracking for featured ad
        state.featuredAdTracking = {
            chosen_option: chosenOption
        };
        state.feed_version = feed_version;

        if (location && location.state && location.state.fromBundleResultset) {
            state.fromBundleResultset = location.state.fromBundleResultset;
        }

        return {
            pathname: listingType === LISTING_TYPES.RE_PORJECTS ? buildURL({ base: `/project/${item.ad_id || item.id}-${slugify(item.name)}-${slugify(item.locations.cityLabel)}` }) : slug,
            state
        };
    }

    handleTrustTagTap = ev => {
        ev.stopPropagation();

        const { track, trackOrigin, source, openModal } = this.props;

        track(INSPECTED_CAR_ICN_CLICK, {
            origin: trackOrigin,
            resultset_type: source,
            select_from: 'listing_page'
        });
        openModal();
    }

    showInspectionTag() {
        const { item: { category_id, inspection_info } = {}, listingType, showInspectionTag } = this.props;
        const isOLXID = this.siteCode === 'olxid';
        const isAdInspected = getIsStoreInspectedCar(inspection_info)
            && (!inspection_info.consent || inspection_info.consent === INSPECTION_INFO_STATUS.APPROVED);

        let enableNewAdpv = this.enableNewAdpvFromConfig;

        if (isOLXID) {
            enableNewAdpv = this.enableNewAdpvFromConfig && showInspectionTag;
        }
        else {
            enableNewAdpv = this.enableNewAdpvFromConfig;
        }

        return enableNewAdpv
            && category_id === this.carCategory
            && listingType === LISTING_TYPES.ITEMS
            && isAdInspected;
    }

    render() {
        const { item, userType, friendsInCommon, showStatistics, listingType, visualizationType, getListingDescription, tag, itemCardType, imageHeight, imageWidth, config, loggedUser, source, showSponseredTag, showDealerTag, dealerData, isDealerProfile, isDealerPreview, userIcon, isLCPCandidate } = this.props;
        const { title, favorites, views, main_info, user_type, isSpinViewAvailable, user_id, dealer_showroom_enabled, dealer_logo_url } = item;
        const { image, price, display, date } = this.property;
        const defaultSize = this.getDefaultSize();
        const itemLocation = getItemLocation(item);
        const isClassified = true;
        const isMyOwnAd = isClassified ? user_id !== loggedUser?.id : true;

        let { statusClass, status } = this.renderStatus(display);

        const isFeaturedAd = getIsFeaturedAd(item);

        const showFeaturedTag = this.featuredTag && isFeaturedAd;
        const isSponsored = getIsSponsoredAd(item) && showSponseredTag;

        const staticAssetUrl = config.get('staticAssets');

        const userTypeLogo = userIcon
            ? (<img
                alt="userTypeLogo"
                src={ userIcon }
                className={ css.userTypeLogo }
            />)
            : null;

        // status refers to the Featured tag / tags shown on Image
        let dealerTag;

        if (isDealerProfile || isDealerPreview) {
            dealerTag = dealerData?.showroom_status && dealerData?.showroom?.logo?.url && <img
                alt="dealer_logo"
                src={ dealerData.showroom.logo.url }
                className={ css.dealerTag }
            />;
        }
        else {
            dealerTag = showDealerTag && dealer_showroom_enabled && dealer_logo_url && <img
                alt="dealer_logo"
                src={ dealer_logo_url }
                className={ css.dealerTag }
            />;
        }

        ({ status, statusClass } = getStatus({ tags: item?.tags || convertKeysToSnakeCase(item?.monetizationInfo?.tags), olxAutosTags: userTypeLogo, showFeaturedTag, previousStatus: status, previousClass: statusClass, theme: this.theme, isSponsored, staticAssetUrl }));

        const enableCall = isCocoFofoUser((user_type || userType), item?.category_id) && this.phoneInListing.enabled;

        return (
            <ReactObserver tag={ tag } data-aut-id="itemBox3" data-aut-category-id={ this.props.item.category_id } onChange={ this.isVisible } className={ classNames(css[`${this.props.visualizationType}ItemCard`], this.props.className) }>
                <Link
                    to={ !isDealerPreview && this.getLinkToProp() }
                    className={ statusClass }
                    onClick={ !isDealerPreview && this.onClick } >
                    {isSpinViewAvailable && <SpinViewTag isFeaturedTagVisible={ showFeaturedTag || !!userTypeLogo } isGridorBigView={ visualizationType !== 'list' } />}
                    {image
                        && <ItemImage
                            image={ image }
                            friendsInCommon={ friendsInCommon }
                            defaultSize={ defaultSize }
                            alt={ title }
                            visualizationType={ visualizationType }
                            listingType={ listingType }
                            height={ imageHeight }
                            width={ imageWidth }
                            isCarImage={ item.category_id === config.get(CONFIG.OLX_AUTOS, CONFIG.OLX_AUTOS_CAR_CATEGORY) }
                            isLCPCandidate={ isLCPCandidate }
                        />
                    }
                    {dealerTag ? <span className={ css.dealerContainer }>
                        {dealerTag}
                    </span> : null}

                    { isMobile || this.props.isShowVideoTag
                    && <span className={ css.videoOverDesktop }>
                        <IconWrapper className={ css.videoIconDesktop } icon="play" size={ 12 } color="white" />
                        <span className={ css.videoIconDesktop }>Video</span>
                    </span>
                    }
                    {isMobile && this.props.isShowVideoTag
                    && <span className={ css.videoOverMobile }>
                        <IconWrapper className={ css.videoIconMobile } icon="play" size={ 12 } color="white" />
                        <span className={ css.videoIconMobile }>Video</span>
                    </span>}
                    {
                        getListingDescription(item)
                    }
                    {
                        listingType === LISTING_TYPES.ITEMS && <div className={ css.resume }>
                            {status && <div className={ css.status }>{status}</div>}
                            {price
                                && <span className={ css.price } data-aut-id="itemPrice">{price}</span>
                            }
                            {main_info && !enableCall && <span className={ css.details } data-aut-id="itemDetails">{main_info}</span>}
                            {title && <span className={ classNames(css.title, { [css.mainTitle]: !price && !main_info }) } data-aut-id="itemTitle">{title}</span>}
                            {!enableCall && <div className={ css.bottomCardInfo }>
                                {
                                    itemLocation && (
                                        <span className={ css.location } data-aut-id="item-location">
                                            {itemLocation}
                                        </span>)
                                }
                                {
                                    date && (
                                        <span className={ css.date }>{date}</span>
                                    )
                                }
                            </div>}
                            {showStatistics && !enableCall && (
                                <div className={ css.statistics }>
                                    {!isEmpty(favorites) && (
                                        <div className={ css.favorites }>
                                            <i className={ `${icons.panameraIcons} ${icons['icon-FavoriteOn']}` } />
                                            <span>{favorites.count}</span>
                                        </div>
                                    )}
                                    {views !== null && (
                                        <div className={ css.views }>
                                            <i className={ `${icons.panameraIcons} ${icons['icon-View']}` } />
                                            <span>{views}</span>
                                        </div>
                                    )}
                                </div>
                            )}
                            { enableCall && <CallButton phoneNumber={ this.phoneInListing.data?.number } item={ item } data-aut-id="itemCardCallBtn" />}
                        </div>
                    }
                </Link>
                {
                    this.showFavBtn
                        && listingType === LISTING_TYPES.ITEMS && isMyOwnAd
                        && <ItemFavouriteIcon item={ item }
                            userType={ userType }
                            className={ classNames(css.favIcon, { [css.favIconOlx]: this.theme === THEMES.OLX }, { [css.favIconOlxMob]: this.theme === THEMES.OLX && isMobile && source !== HOME }) }
                            btnClassName={ this.theme === THEMES.OLX ? css.favIconBtn : '' } />
                }
                {this.showInspectionTag() && <div
                    className={ classNames(css[`${this.props.visualizationType}ViewInspectedIcon`], {
                        [css[`${PLATFORM}FeedItemCard`]]: itemCardType === ITEM_CARD_TYPE.FEED
                    }) }
                    onClick={ this.handleTrustTagTap }
                >
                    {this.carInspectedIconUri && <ImageWrapper
                        fileName={ this.carInspectedIconUri }
                        fileSize={ X }
                        className={ css.carInspectedImage }
                        types={ WEBP_SVG_TYPE } />
                    }
                </div>
                }
            </ReactObserver>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    const { isDealerPreview } = ownProps;
    const dealer = getUserInfoByCategory(userSelector(state, ownProps.item?.user_id), ownProps.item?.category_id);
    const userIcon = ownProps.item?.user_icon || dealer?.icon_url;

    return {
        categories: state.categories,
        trackOrigin: state.track.origin,
        generalConfig: configSelector(state),
        selectFrom: (ownProps.location.state && ownProps.location.state.source),
        loggedUser: getLoggedUser(state),
        userType: dealer.dealer_type || USER_TYPE.REGULAR,
        userIcon,
        user: getUserDataSelector(state),
        dealerData: isDealerPreview ? getDealerPreviewDataSelector(state) : getDealerDataSelector(state)
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        setSelectFromTrack: origin => dispatch(setSelectFrom(origin))
    };
};

export default compose(
    withConfig,
    withTrack,
    withRouter,
    withBackButtonHandling(trustTagHash),
    connect(mapStateToProps, mapDispatchToProps)
)(ItemCard);
